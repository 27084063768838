import { createSlice } from '@reduxjs/toolkit'
import { EnglandSvg } from 'assets/svg'

export const languageSlice = createSlice({
    name: 'languageStore',
    initialState: {
        selectedLanguage:  { code: "EN", name: "English", icon: <EnglandSvg  width={20} /> }, 
    },
    reducers: {
        setSelectedLanguage: (state, action) => {
            state.selectedLanguage = action.payload
        }
    },
})

export const {setSelectedLanguage} = languageSlice.actions

export default languageSlice.reducer