  // eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setAffiliateCode } from "store/affiliate/affiliateSlice";
import Layout from "./components/layout";
import { publicRoutes, protectedRoutes } from "../src/configs/routes.config";
import Header from "components/template/header";
import Footer from "components/template/footer";
import useQuery from "utils/hooks/useQuery";
import "./App.css";


function App() {
  const [header, setHeader] = useState(false);
  const [footer, setFooter] = useState(false);

  const countApi = useRef(0);
  const dispatch = useDispatch();
  const query = useQuery();
  const affiliateCode = query.get('ref');
  const location = useLocation();

// dispatch afiliate code to store for refference  
  useEffect(() => {
    if (!countApi.current) {
      dispatch(setAffiliateCode(affiliateCode))
      countApi.current = countApi.current + 1;
    }
  }, [])

// set header and footer base on page route 
  useEffect(() => {
    const allRoutes = [...protectedRoutes, ...publicRoutes]
    const currentRoute = allRoutes?.find((route) => {
      const regex = new RegExp(`^${route.path.replace(/:slug/, '[^/]+')}$`);
      return regex.test(location.pathname);
    })
    if (currentRoute) {
      setHeader(currentRoute.header);
      setFooter(currentRoute.footer);
    } else {
      setHeader(false);
      setFooter(false);
    }
  }, [location.pathname]);

  return (
    <>
      {header && <Header />}
      <Layout />
      {footer && <Footer />}
    </>
  );
}

export default App;
