import { createSlice } from '@reduxjs/toolkit'

export const footerSlice = createSlice({
    name: 'footerStore',
    initialState: {
        footerData: null, 
    },
    reducers: {
        setFooterData: (state, action) => {
            state.footerData = action.payload
        }
    },
})

export const {setFooterData} = footerSlice.actions

export default footerSlice.reducer