import React from "react";
import { USER } from "../../constants/roles.constant";

const userPublicRoute = [
  {
    key: "home",
    component: React.lazy(() => import("pages/homePage")),
    path:"/",
    authority: [],
    header: true,
    footer: true,
  },
  {
    key: "our-services",
    path: `/our-services`,
    component: React.lazy(() => import("pages/ourServicesPage")),
    authority: [USER],
    header: true,
    footer: true,
  },
  {
    key: "report",
    path: `/our-services/:slug`,
    component: React.lazy(() =>
      import("views/ourServices/components/ourServiceDetails")
    ),
    authority: [USER],
    header: true,
    footer: true,
  },
  {
    key: "application-process",
    path: `/application-process`,
    component: React.lazy(() =>import("views/applicationProcess")),
    authority: [USER],
    header: true,
    footer: true,
  },
  {
    key: "about-us",
    path: `/about-us`,
    component: React.lazy(() => import("pages/aboutUsPage")),
    authority: [USER],
    header: true,
    footer: true,
  },
  {
    key: "our-team",
    path: `/about-us/our-team`,
    component: React.lazy(() => import("pages/ourTeamPage")),
    authority: [USER],
    header: true,
    footer: true,
  },
  {
    key: "our-technology",
    path: `/about-us/our-technology`,
    component: React.lazy(() => import("pages/ourTechnologyPage")),
    authority: [USER],
    header: true,
    footer: true,
  },
  {
    key: "sample-report",
    path: `/about-us/sample-report`,
    component: React.lazy(() => import("pages/sampleReportPage")),
    authority: [USER],
    header: true,
    footer: true,
  },
  {
    key: "blogs",
    path: `/blogs`,
    component: React.lazy(() => import("views/blogs")),
    authority: [USER],
    header: true,
    footer: true,
  },
  {
    key: "blog-details",
    path: `/blogs/blog-details/:slug`,
    component: React.lazy(() => import("views/blogs/components/blogDetails")),
    authority: [USER],
    header: true,
    footer: true,
  },
  {
    key: "contact-us",
    path: `/contact-us`,
    component: React.lazy(() => import("pages/contactUsPage")),
    authority: [USER],
    header: true,
    footer: true,
  },
  
  {
    key: "privacy-Policy",
    path: `/privacy-Policy`,
    component: React.lazy(() => import("pages/privacyPolicyPage")),
    authority: [USER],
    header: true,
    footer: true,
  },
  {
    key: "terms-And-Condition",
    path: `/terms-And-Condition`,
    component: React.lazy(() => import("pages/termsAndConditionPage")),
    authority: [USER],
    header: true,
    footer: true,
  },
  
  {
    key: "error",
    path: `/error`,
    component: React.lazy(() => import("views/404Error/index")),
    authority: [USER],
    header: true,
    footer: true,
  },
];

export default userPublicRoute;