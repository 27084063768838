import { useSelector, useDispatch } from "react-redux";
import { setUser, userLoggedOut } from "../../store/auth/userSlice";
import { onSignInSuccess, onSignOutSuccess } from "../../store/auth/sessionSlice";
import { REDIRECT_URL_KEY } from "../../constants/app.constant";
import { useNavigate, useParams } from "react-router-dom";
import useQuery from "./useQuery";
import appConfig from "../../configs/app.config";
import { getUserData, singIn, singUp } from "apiServices/authServices";
import { OpenNotificationPopup } from "components/shared";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, expired } = useSelector((state) => state?.auth?.session);

  // function is for signUp 
  const signUp = async (formData) => {
    try {
      const response = await singUp(formData);
      if (response?.status) {
        const { token, data } = response;
        dispatch(onSignInSuccess(token));
        const userData = data;
        // console.log("🚀 ~ signUp ~ userData:", userData)
        userData &&
          dispatch(
            setUser({
              client_id: userData?.client_id ? userData.client_id : "",
              profile_photo: userData?.profile_photo
                ? userData.profile_photo
                : "https://static.vecteezy.com/system/resources/thumbnails/002/002/403/small/man-with-beard-avatar-character-isolated-icon-free-vector.jpg",
              client_full_name: userData?.client_full_name ? userData.client_full_name : "Guest",
              birth_date: userData.birth_date ? userData.birth_date : "",
              client_email: userData?.client_email
                ? userData.client_email
                : "guest@gmail.com",
              password: userData?.password ? userData.password : "",
            })
          );

        if (userData?.client_id) {
          // const redirectUrl = serviceSlug;
          // navigate(redirectUrl ? `/our-services/${serviceSlug}` : appConfig.authenticatedEntryPath);
          // localStorage.removeItem("serviceSlug")
          return response;
        } else {
          return response;
        }
      } else {
        // console.log("🚀 ~ signUp ~ response?.message else:", response?.message)
        // OpenNotificationPopup("danger",response?.message)
        return response;
      }
    } catch (errors) {
      // console.log("signIn error inside catch:", errors.message);
      OpenNotificationPopup("danger", errors?.message)
      return {
        status: false,
        message: errors?.response?.data?.message || errors?.toString(),
      };
    }
  };

  // function is for signIn 
  const signIn = async ({ client_email, password, redirectUrl }) => {
    try {
      const infoData = {
        client_email,
        password,
      };
      const response = await singIn(infoData);
      if (response.status) {
        const { token, data } = response;
        dispatch(onSignInSuccess(token));
        const userData = data;
        // console.log("🚀 ~ signIn ~ userData:", userData)
        userData &&
          dispatch(
            setUser({
              client_id: userData.client_id ? userData.client_id : "",
              profile_photo: userData?.profile_photo
                ? userData.profile_photo
                : "https://static.vecteezy.com/system/resources/thumbnails/002/002/403/small/man-with-beard-avatar-character-isolated-icon-free-vector.jpg",
              client_full_name: userData.client_full_name ? userData.client_full_name : "Guest",
              birth_date: userData.birth_date ? userData.birth_date : "",
              client_email: userData.client_email
                ? userData.client_email
                : "guest@gmail.com",
              password: userData.password ? userData.password : "",
            })
          );

        if (userData?.client_id) {
          // const redirectUrl = serviceSlug;
          // console.log("🚀 ~ signIn ~ redirectUrl:", redirectUrl ? `/our-services/${serviceSlug}` : appConfig.authenticatedEntryPath)
          // navigate(redirectUrl ? `/our-services/${serviceSlug}` : appConfig.authenticatedEntryPath);
          // localStorage.removeItem("serviceSlug")
          return response;
        } else {
          // console.log("enter in to else", response)
          return response;
        }
      } else {
        // console.log("🚀 ~ signUp ~ response?.message else:", response?.message)
        // OpenNotificationPopup("danger",response?.message)
        return response;
      }
    } catch (errors) {
      // console.log("signIn error:", errors.message);
      return {
        status: false,
        message: errors?.response?.data?.message || errors?.toString(),
      };
    }
  };

  // This function  is not used 
  const setUserData = async (client_id, redirectUrl) => {
    try {
      const response = await getUserData(client_id);
      if (response.status) {
        const userData = response?.data[0];
        // console.log("🚀 ~ setUserData ~ userData:", userData)
        userData &&
          dispatch(
            setUser({
              client_id: userData.client_id ? userData.client_id : "",
              profile_photo: userData?.profile_photo
                ? userData.profile_photo
                : "https://static.vecteezy.com/system/resources/thumbnails/002/002/403/small/man-with-beard-avatar-character-isolated-icon-free-vector.jpg",
              client_full_name: userData.client_full_name ? userData.client_full_name : "Guest",
              birth_date: userData.birth_date ? userData.birth_date : "",
              client_email: userData.client_email
                ? userData.client_email
                : "guest@gmail.com",
              password: userData.password ? userData.password : "",
            })
          );

          // Handel karvanu baki 
        if (userData?.client_id) {
          // console.log("Enter into if block")
          navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
          return response;
        } else {
          // console.log("Enter into else block")
          navigate(redirectUrl ? redirectUrl : appConfig.tourPath);
          return response;
        }
        // return response;
      } else {
        return response;
      }
    } catch (errors) {
      // console.log("signIn error:", errors.message);
      return {
        status: false,
        message: errors?.response?.data?.message || errors?.toString(),
      };
    }
  };

  // function for signout 
  const handleSignOut = () => {
    localStorage.removeItem("serviceSlug");
    dispatch(onSignOutSuccess());
    dispatch(userLoggedOut());
    navigate(appConfig.unAuthenticatedEntryPath);
  };
  const signOut = async () => {
    handleSignOut();
  };

// function for check authenticated or not 
  const checkAuthenticated = () => {
    if (expired > new Date().getTime()) {
      // console.log("Enter into If")
      return true;
    } else {
      // console.log("Enter into Else")
      handleSignOut();
      return false;
    }
  };

  return {
    // authenticated: true,
    authenticated: token && signIn && checkAuthenticated(),
    signUp,
    signIn,
    setUserData,
    signOut,
  };
}

export default useAuth;
