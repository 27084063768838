import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

const SampleReportCard = ({ mainTitle, sections }) => {
  const [accordionOpen, setAccordionOpen] = useState(null); // State to track open accordion

  // Function to toggle accordion
  const toggleAccordion = (index) => {
    if (accordionOpen === index) {
      setAccordionOpen(null);
    } else {
      setAccordionOpen(index);
    }
  };

  return (
    <div className="w-full mx-auto p-4">
      <div className="border rounded-xl">
        {/* Main Accordion Title */}
        <div
          className="border-b py-2 text-center bg-[#003A7B] text-white text-2xl font-medium font-outfit rounded-t-xl  px-4 cursor-pointer relative"
          onClick={() => toggleAccordion(-1)}
        >
          <h2 className="text-lg font-bold">{mainTitle}</h2>
          {/* Dropdown arrow icon */}
        </div>

        {/* Accordion Sections */}
        {sections.map((section, index) => (
          <div key={index}>
            {/* Section Title - Click to toggle */}
            <div
              className="border-b py-2 px-4 cursor-pointer relative"
              onClick={() => toggleAccordion(index)}
            >
              <h3 className="text-lg text-[#003A7B] font-outfit px-4 py-2 bg-[#EDF5FF] rounded-lg font-semibold">
                {section.title}
              </h3>
              {/* Dropdown arrow icon */}
              <span
                className={`absolute inset-y-3 right-6 item-center top-1/2 transform -translate-y-1/2 ${
                  accordionOpen === index ? "rotate-180" : ""
                }`}
              >
                <MdKeyboardArrowDown />
                {/* Unicode for down arrow */}
              </span>
            </div>

            {/* Sub-reports - Show if this section is open */}
            {accordionOpen === index && (
              <div className=" px-8 py-3">
                {section.subReports.map((subReport, subIndex) => (
                  <div key={subIndex} className="mb-2">
                    {/* Sub-report details */}
                    <p className="font-medium font-outfit text-lg border-b-[1px] border-[#003A7B] pb-1 text-[#003A7B]">
                      {subReport.title}
                    </p>
                    {/* <p className="text-gray-600">{`${subReport.grade}`}</p> */}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SampleReportCard;
