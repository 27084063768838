import React from 'react'

import { Button } from 'components/ui';
import CloseButton from "components/ui/closeButton";

import { FaRegCircleCheck } from "react-icons/fa6";

const Model = ({ ...props }) => {
    const {
        heading = "",
        headingIcon,
        headingClass = "",
        content = "",
        contentClass = "",
        btnId = "",
        btnClass = "",
        btn1Id = "",
        btn1Class = "",
        btn1variant,
        btn1Text = "",
        btn1isLoading = false,
        btn1Click,
        btn2Class = "",
        btn2variant,
        btn2Text = "",
        btn2Click,
        setIsModelOpen } = props
    const handleClose = (e) => {
        e.stopPropagation();
        setIsModelOpen(false);
    };
    return (
        <>
            <div className="w-full h-full fixed top-0 left-0 shadow-2xl bg-gray-800 bg-opacity-50 z-10 flex items-center justify-center ">
                <div className='container'>
                    <div className="2xl:w-1/3 lg:w-1/2 sm:w-3/4  bg-white md:py-14 md:px-16 px-5 py-5 rounded-md w-full mx-auto relative">
                        <CloseButton className="absolute top-2 right-3  cursor-pointer text-2xl text-[#003A7B]" onClick={(e) => handleClose(e)} />
                        <div className=" flex flex-col justify-center items-center ">
                            {headingIcon}
                            <p className={`lg:text-4xl font-syne font-bold text-[#003A7B] text-2xl pt-6 ${headingClass}`}>
                                {heading}
                            </p>
                            <p className={`text-[#474747] text-xl pt-4 ${contentClass}`}>
                                {content}
                            </p>
                        </div>
                        <div className={`w-full flex justify-center pt-10 gap-4 ${btnClass} `}  >
                            <Button
                                className={`${btn1Class}`}
                                variant={`${btn1variant}`}
                                type="button"
                                loading={btn1isLoading}
                                onClick={btn1Click}
                            >
                                {btn1Text}
                            </Button>
                            <Button
                                className={`${btn2Class}`}
                                variant={`${btn2variant}`}
                                type="button"
                                onClick={(e) => btn2Click(e)}
                            >
                                {btn2Text}
                            </Button>
                        </div>
                    </div>
                </div >
            </div>
        </>
    )
}

export default Model
