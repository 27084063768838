import React from "react";

const Banner = ({ bannerImage, bannerTitle, }) => {
  return (
    <>
      <div className="relative">
        <img className="w-full md:h-[750px]" src={bannerImage} alt="bannerImage" />

        <div className="absolute inset-0 bg-blue-500 opacity-30"></div>

        <div className="absolute md:bottom-10 bottom-5 left-5 md:left-20 font-syne text-2xl  font-semibold">
          <span className="font-syne font-bold text-2xl md:text-7xl">
            {bannerTitle}
          </span>
        </div>
      </div>
    </>
  );
};

export default Banner;
