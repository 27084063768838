import { EnglandSvg, IndiaIconSvg } from "assets/svg";


export const webNavigationLinks = [
    { value: "home", label: "Home", path: "/" },
    { value: "services", label: "Our Services", path: "/our-services" },
    { value: "about", label: "About Us", path: "/about-us" },
    { value: "blogs", label: "Blogs", path: "/blogs" },
    { value: "contact", label: "Contact Us", path: "/contact-us" },
];


export const languageOptions = {
    languages: [
        { code: "EN", name: "English", icon: <EnglandSvg width={20} /> },
        { code: "GU", name: "Gujarati", icon: <IndiaIconSvg width={20} /> },
    ]
}