import { combineReducers } from "redux";
import auth from './auth'
import affiliate from "./affiliate"
import language from "./language"
import footer from "./footer"

// this is rootReducer of all reducer 
const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    affiliate,
    language,
    footer,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
