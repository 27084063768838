import { SectionTitle } from "components/shared";
import React from "react";

const LeftRightContent = ({
  content,
  title,
  description,
  button,
  position = "left",
}) => {
  // console.log("description",description)
  return (
    <>
      <div
        className={`md:flex  ${position === "left" ? " md:flex-row" : "md:flex-row-reverse"
          }  md:justify-center md:items-center  md:gap-10`}
      >
        <div className="md:w-1/2">
          <div className="">
            <SectionTitle title={title} />
          </div>
          <div className="block md:hidden">{content}</div>
          <div className={description ? `text-left py-6 lg:py-9` : ""}>
            <p>{description}</p>
          </div>
          <div className="text-center">{button}</div>
        </div>
        <div className="hidden md:block md:w-1/2">{content}</div>
      </div>
    </>
  );
};

export default LeftRightContent;
